import './app.css';
import React, {Suspense} from "react";
import {HashRouter, Route, Routes} from "react-router-dom";
import Home from "./components/home";

const App = () => {
    return (
        <Suspense fallback="loading">
            <HashRouter>
                <Routes>
                    <Route exact path="/" element={<Home/>}/>
                </Routes>
            </HashRouter>
        </Suspense>
    );
}

export default App;
