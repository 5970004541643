import './index.css';
import React, {useEffect, useState} from 'react';
import Slider from "react-slick";
import {motion} from "framer-motion";
import Navigation from "../navigation";
import Footer from "../footer";
import LoadingPage from "../loadingpage";
import {AuthAPI} from "../../api/AuthAPI";
import {addAnalytics, getFileUrl} from "../../utils/Utils";
import IconMenu from "../../assets/images/icon-menu.png";
import {useGlobalContext} from "../../context";
import Menu from "../menu";
import ShopHours from "../shop_hours";
import Basket from "../basket";

const SliderSettings = {
    dots: false,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3500,
    autoplaySpeed: 1000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const RenderSliderMenu = ({item, setCurrentMenu}) => {
    let image_menu_cover = getFileUrl(item, 'url_menu_cover', IconMenu);
    return (
        <div className={"position-relative"} onClick={() => setCurrentMenu(item.id)} style={{borderRadius: '.75em'}}>
            <img src={image_menu_cover} className="img-fluid me-3 d-block" alt={item.name}
                 style={{background: '#f8f8f8', borderRadius: '.75em'}}/>
        </div>
    );
}

const RenderListMenu = ({item, setCurrentMenu}) => {
    let image_menu_logo = getFileUrl(item, 'url_menu_logo', IconMenu);
    return (
        <div className={"col-6 col-lg-4 col-md-6 col-sm-6 p-0"}>
            <div className={"category-container"}>
                <motion.div className={"category-item cursor-pointer"} whileHover={{scale: 1.09}} whileTap={{scale: 0.9}}>
                    <div onClick={() => setCurrentMenu(item.id)}>
                        <img src={image_menu_logo} className="img-fluid d-block" alt={item.name}
                             style={{background: '#f8f8f8'}}/>
                        <label className={"fw-bold"}>{item.name}</label>
                    </div>
                </motion.div>
            </div>
        </div>
    );
}

const Home = () => {
    //const KEY_MENU_DATA = 'gw-data';
    const context = useGlobalContext();
    const [isDataComplete, setIsDataComplete] = useState(false);
    const [data, setData] = useState({store: '', list_menu: '', list_category: ''});
    const isWithShopHours = false;

    useEffect(() => {
        async function fetchData() {
            let data = {token_menu: context.TOKEN_MENU_DOMAIN};
            let responseData = await AuthAPI.show(data);
            if (responseData.status === 200) {
                if (responseData.data.status === true) {
                    setData({
                        ...data
                        , store: responseData.data.context.store ?? ''
                        , list_menu: responseData.data.context.list_menu ?? ''
                        , list_category: responseData.data.context.list_category ?? ''
                    });

                    // Add Analytics
                    let currentStore = responseData.data.context.store ?? null;
                    let currentStoreId = currentStore !== null ? currentStore.id : null;
                    await addAnalytics(currentStoreId, currentStoreId, 'store-view');
                }
                return responseData.data.status;
            } else return false;
        }

        fetchData().then((response) => {
            setIsDataComplete(response)
        });
    }, [context.TOKEN_MENU_DOMAIN]);

    if (!isDataComplete) return <LoadingPage/>;

    return (
        <div className={"body-wrapper"}>
            { isWithShopHours && <ShopHours store_id={data.store.id}/>}
            <Navigation store={data.store} listMenu={data.list_menu}/>
            {
                context.showBasket
                    ?
                    <Basket/>
                    :
                    <div className={"container"}>
                        {
                            (context.currentMenuId === 0)
                                ? <>
                                    <div className="row">
                                        <div className="col-12 mt-3" style={{borderRadius: '.75em'}}>
                                            <Slider {...SliderSettings}>
                                                {
                                                    data.list_menu && data.list_menu.map((item, key) =>
                                                        <RenderSliderMenu key={key.toString()} item={item}
                                                                          setCurrentMenu={context.setCurrentMenu}/>
                                                    )
                                                }
                                            </Slider>
                                        </div>
                                    </div>
                                    <div className="row m-0">
                                        {
                                            data.list_menu && data.list_menu.map((item, key) =>
                                                <RenderListMenu key={key.toString()} item={item}
                                                                setCurrentMenu={context.setCurrentMenu}/>
                                            )
                                        }
                                    </div>
                                </>
                                : <Menu currentMenuId={context.currentMenuId}/>
                        }
                    </div>
            }
            {context.currentMenuId === 0 && <Footer/>}
        </div>
    );
}

export default Home;