import ClientAPI from './ClientAPI';

export const AuthAPI = {
    show: function (data) {
        return ClientAPI.request({
            method: "POST",
            url: `/api/menu-domain/show`,
            data: data,
        });
    },

    myStoreShopHours: function (data) {
        return ClientAPI.request({
            method: "POST",
            url: `api/store/shop-hours`,
            data: data,
        });
    },

    myStoreSettings: function (data) {
        return ClientAPI.request({
            method: "POST",
            url: `api/store/settings`,
            data: data,
        });
    },

    analytics: function (type, data = null) {
        return ClientAPI.request({
            method: "POST",
            url: `api/add-analytics/${type.toLowerCase()}`,
            data: data,
        });
    },

    createOrder: function (data) {
        return ClientAPI.request({
            method: "POST",
            url: `/api/menu-order/create`,
            data: data
        });
    },

    getStatusMenuOrder: function (data) {
        return ClientAPI.request({
            method: "POST",
            url: `/api/menu-order/status`,
            data: data
        });
    },
}
