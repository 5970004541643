import React from "react";

export const SVG_SHOPPING_CART = (props) => (
    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shopping-cart"
         className={"svg-inline--fa fa-shopping-cart " + (props.style ?? " fa-w-18 fs-4")}
         role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"
         style={{transformOrigin: '0.5625em 0.5em'}}>
        <g transform="translate(288 256)">
            <g transform="translate(0, 0)  scale(0.5625, 0.5625)  rotate(0 0 0)">
                <path fill="currentColor"
                      d="M528.12 301.319l47.273-208C578.806 78.301 567.391 64 551.99 64H159.208l-9.166-44.81C147.758 8.021 137.93 0 126.529 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24h69.883l70.248 343.435C147.325 417.1 136 435.222 136 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-15.674-6.447-29.835-16.824-40h209.647C430.447 426.165 424 440.326 424 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-22.172-12.888-41.332-31.579-50.405l5.517-24.276c3.413-15.018-8.002-29.319-23.403-29.319H218.117l-6.545-32h293.145c11.206 0 20.92-7.754 23.403-18.681z"
                      transform="translate(-288 -256)"></path>
            </g>
        </g>
    </svg>
)

export const SVG_BELL = (props) => (
    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bell"
         className={"svg-inline--fa fa-bell " + (props.style ?? " fa-w-18 fs-4")} role="img"
         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
         style={{transformOrigin: '0.5625em 0.5em'}}>
        <g transform="translate(224 256)">
            <g transform="translate(0, 0)  scale(0.625, 0.625)  rotate(0 0 0)">
                <path fill="currentColor"
                      d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z"
                      transform="translate(-224 -256)"></path>
            </g>
        </g>
    </svg>
)

export const SVG_SETTINGS = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="37" viewBox="0 0 16 16" fill="none">
        <circle cx="2" cy="2" r="2" fill="#6C6E71"></circle>
        <circle cx="2" cy="8" r="2" fill="#6C6E71"></circle>
        <circle cx="2" cy="14" r="2" fill="#6C6E71"></circle>
        <circle cx="8" cy="8" r="2" fill="#6C6E71"></circle>
        <circle cx="8" cy="14" r="2" fill="#6C6E71"></circle>
        <circle cx="14" cy="8" r="2" fill="#6C6E71"></circle>
        <circle cx="14" cy="14" r="2" fill="#6C6E71"></circle>
        <circle cx="8" cy="2" r="2" fill="#6C6E71"></circle>
        <circle cx="14" cy="2" r="2" fill="#6C6E71"></circle>
    </svg>
)

export const SVG_TRASH = (props) => (
    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash-alt"
         className="svg-inline--fa fa-trash-alt fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 448 512">
        <path fill="currentColor"
              d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"></path>
    </svg>
)

export const SVG_ICON_CLOSE = () => {
    return (<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m4 4 8 8m-8 0 8-8" stroke="currentColor" strokeLinecap="round" strokeWidth="1.5"></path></svg>);
}

export const SVG_ICON_SUCCESS = () => {
    return (<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check-circle" className="svg-inline--fa fa-check-circle fa-w-16 text-white fs-3" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path></svg>);
}

export const SVG_ICON_DANGER = () => {
    return (<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle" className="svg-inline--fa fa-times-circle fa-w-16 text-white fs-3" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg>);
}

export const SVG_ICON_WARNING = (props) => {
    return (<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation-circle" className={"svg-inline--fa fa-exclamation-circle fa-w-16 fs-3 " + (props.style ?? 'text-white')} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"></path></svg>);
}

export const SVG_ICON_INFO = () => {
    return (<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" className="svg-inline--fa fa-info-circle fa-w-16 text-white fs-3" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path></svg>);
}

export const SVG_LOADING = (props) => {
  return (
      <svg style={{margin: 'auto', background: 'none', display: 'block', shapeRendering: 'auto'}} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <path fill="none" stroke={(props.style ?? "#2e6da1")} strokeWidth="9" strokeDasharray="42.76482137044271 42.76482137044271" d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z" strokeLinecap="round" style={{transform:'scale(0.88)', transformOrigin: '50px 50px'}}><animate attributeName="stroke-dashoffset" repeatCount="indefinite" dur="1.3157894736842106s" keyTimes="0;1" values="0;256.58892822265625"></animate>
          </path>
      </svg>);
}