import React, {useEffect, useState} from 'react';
import {motion} from "framer-motion";
import {useGlobalContext} from "../../context";
import {getFileUrl} from "../../utils/Utils";
import IconConsumable from "../../assets/images/icon-plate.png";
import IconBasketComplete from "../../assets/images/icon-basket_complete.png";
import {SVG_ICON_WARNING, SVG_LOADING, SVG_TRASH} from "../../utils/AppSvg";
import HandlerAPIMessage from "../../constants/handle-api-message";
import MessageBanner from "../../utils/MessageBanner";
import ModalContainer from "../modal_container";
import Modal from "../modal";
import {AuthAPI} from "../../api/AuthAPI";

const BasketItem = ({basketItem, dataOrderConfirmed}) => {
    const context = useGlobalContext();
    let current_image_consumable = getFileUrl(basketItem.item, 'url_menu_consumable_cover', IconConsumable);
    const [loadingRequest, setLoadingRequest] = useState(false);

    function HandlerOnItem2Basket(item, action) {
        setLoadingRequest(true);
        switch (action) {
            case 'add': context.AddItem2Basket(item, item.variation_selected); break;
            case 'remove': context.RemoveItem2Basket(item); break;
            case 'remove-all': context.RemoveItem2Basket(item,0); break;
        }
        setTimeout(() => {
            setLoadingRequest(false)
        }, 1000);
    }

    return (
        <div className="row align-items-center">
            <div className="col-4 col-sm-2 text-end">
                <img alt={basketItem.item.title} src={current_image_consumable} className={"consumable-image image aspect-radio-1-1 w-100"} style={{borderRadius: '5%'}}/>
            </div>
            <div className={"col-8 col-sm-10"}>
                <div className="row align-items-center text-end">
                    <h6 className="mb-1 fw-bold fs--1 text-dark">{basketItem.item.name} <small>x {basketItem.qta}</small></h6>
                    <div className={"w-100 mb-0"}>
                        {
                            basketItem.item.variation_selected !== null
                                ? <small className={"me-1"}>Cad. {parseFloat(basketItem.item.variation_selected.price).toFixed(2)}€</small>
                                : <small className={"me-1"}>Cad. {parseFloat(basketItem.item.price).toFixed(2)}€</small>
                        }
                        <label className={"fw-semi-bold"}>Tot. {parseFloat(basketItem.price).toFixed(2)} €</label>
                    </div>
                </div>
            </div>
            {
                !dataOrderConfirmed.status &&
                <div className={"col-12 mt-2"}>
                    <div className="d-flex justify-content-end col-5 offset-7">
                        <div className={"me-3"}>
                            <div className="input-group input-group-sm">
                                <button type="button" className="px-2 border-300 btn btn-outline-secondary btn-sm"
                                        onClick={() => HandlerOnItem2Basket(basketItem.item, 'remove')}>-</button>
                                <input min="1" type="number" className="text-center px-2 input-spin-none form-control"
                                       readOnly={true} value={basketItem.qta}/>
                                <button type="button" className="px-2 border-300 btn btn-outline-secondary btn-sm"
                                        onClick={() => HandlerOnItem2Basket(basketItem.item, 'add')}>+</button>
                            </div>
                        </div>
                        <button type="button" className="px-2 border-300 btn btn-outline-danger btn-sm"
                                onClick={() => HandlerOnItem2Basket(basketItem.item, 'remove-all')}>
                            <SVG_TRASH/>
                        </button>
                    </div>
                </div>
            }
        </div>
    );
}

const Basket = () => {
    const context = useGlobalContext();
    const [apiStatus, setApiStatus] = useState({})
    const [onSubmitRequest, setOnSubmitRequest] = useState(false)
    const [dataBasketList, setDataBasketList] = useState([])
    const [email, setEmail] = useState('')
    const [tableOrder, setTableOrder] = useState('')
    const [dataOrderConfirmed, setDataOrderConfirmed] = useState({
        status: false,
        message: '',
        menu_order_id: null,
        code: '',
        need_to_be_confirm: false,
        can_be_pay_now: false,
        is_reading: false
    })

    useEffect(() => {
        setDataBasketList(context.dataBasket.list)
    }, [context.dataBasket.list])

    useEffect(() => {
        async function fetchData() {
            try {
                let responseGetStatusMenuOrder = await AuthAPI.getStatusMenuOrder({menu_order_id: dataOrderConfirmed.menu_order_id});
                if (responseGetStatusMenuOrder.status === 200) {
                    let contextStoreShopHours = responseGetStatusMenuOrder.data.status ? responseGetStatusMenuOrder.data.context : null;
                    if (contextStoreShopHours.name === 'CONFIRMED_BY_PROFILE') setDataOrderConfirmed({...dataOrderConfirmed, is_reading: contextStoreShopHours.is_reading});
                    else if (contextStoreShopHours.name === 'APPROVED') {
                        setDataOrderConfirmed({...dataOrderConfirmed, need_to_be_confirm: false});
                        return true;
                    }
                }
                return false;
            } catch (e) {
                return false;
            }
        }

        if (dataOrderConfirmed.need_to_be_confirm && !context.dataStoreSettings.store_enabled_order_and_confirm_by_client){
            const interval = setInterval(() => {
                fetchData().then((response) => {
                    if (response) clearInterval(interval);
                });
            }, 5000);
            return () => clearInterval(interval);
        }
    }, [dataOrderConfirmed.need_to_be_confirm]);

    if (context.dataBasket.list.length === 0) {
        return (
            <div className={"container"}>
                <div className={"row"}>
                    <div className={"col-12 mt-3"}>
                        <motion.div initial={{opacity: 0, scale: 0.5}} animate={{opacity: 1, scale: 1}}
                                    transition={{duration: 0.65}} className={"card bg-primary mb-3"}>
                            <div className="card-header">
                                <h5 className={"fw-bold fs--1 text-white m-0"}>Crea il tuo ordine</h5>
                            </div>
                            <div className="card-body bg-light">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <h5>La tua lista ordine è vuota</h5>
                                        <label>Qui ci vanno le cose buone</label><br/>
                                        <button type={"button"} className={"btn btn-sm btn-link ps-0"}
                                                onClick={() => context.setCurrentMenu(0)}>Riprendi da dove hai lasciato</button>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>
        )
    }

    const createOrder = async e => {
        e.preventDefault();
        setOnSubmitRequest(true);
        setApiStatus(HandlerAPIMessage(undefined, null));
        let data = {email: email, table_order: (tableOrder.length === 0 ? null : tableOrder)};
        let response = await context.SendCreateOrderRequest(data);
        if (response.success === true) {
            setDataOrderConfirmed({
                ...dataOrderConfirmed
                , status: response.success
                , message: response.message
                , menu_order_id: response.context.menu_order_id
                , code: response.context.code
                , need_to_be_confirm: response.context.need_to_be_confirm
                , can_be_pay_now: response.context.can_be_pay_now
            });
        }
        else {
            setApiStatus(HandlerAPIMessage(5, response.message));
            setDataOrderConfirmed({...dataOrderConfirmed, status: false, menu_order_id: null, code: ''});
        }
        setOnSubmitRequest(false);
    }

    return (
        <div className={"container"}>
            <div className={"row"}>
                <div className={"col-12 mt-3"}>
                    <motion.div initial={{opacity: 0, scale: 0.5}} animate={{opacity: 1, scale: 1}} transition={{duration: 0.65}} className={"card bg-primary mb-3"}>
                        <div className="card-header">
                            <h5 className={"fw-bold fs--1 text-white m-0"}>Ecco cosa contiene il tuo ordine!</h5>
                        </div>
                        <div className="card-body bg-light">
                            {
                                dataBasketList && dataBasketList.map((basketItem, key) => (
                                    <div key={key.toString()}>
                                        <BasketItem key={key.toString()} basketItem={basketItem} dataOrderConfirmed={dataOrderConfirmed} />
                                        {(key + 1) < dataBasketList.length && <hr/>}
                                    </div>
                                ))
                            }
                        </div>
                    </motion.div>
                    <motion.div initial={{opacity: 0, scale: 0.5}} animate={{opacity: 1, scale: 1}} transition={{duration: 0.65}} className={"card bg-primary mb-0"}>
                        <div className="card-header">
                            <div className="d-flex justify-content-between">
                                <h5 className={"fw-bold text-start fs--1 text-white m-0"}>Tot. {context.dataBasket.total_qta} elementi</h5>
                                <div className="text-end text-600">
                                    <h5 className={"fw-bolder fs--1 text-white m-0"}>Tot € {context.GetBasketTotalAmount()}</h5>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </div>
                {
                    (context.dataStoreSettings.store_enabled_order_and_confirm_by_client === true) &&
                    <motion.div initial={{opacity: 0, scale: 0.5}} animate={{opacity: 1, scale: 1}} transition={{duration: 1.3}}  className={"col-12 mt-3"}>
                        <div className={"card border border-primary mb-0"}>
                            <div className="card-header">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h5 className={"fw-bold text-start fs--1 m-0 col-6"}>Numero del tavolo</h5>
                                    <div className={"text-end text-600 col-6"}>
                                        <h5 className={"fw-bolder fs--1 m-0"}>
                                            <input type={"input"} className={"form-control form-control-sm text-center fw-semi-bold"}
                                                   onChange={(e) => setTableOrder(e.target.value)}
                                                   value={tableOrder}/>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className={"text-start fs--2 mt-2 mb-0 w-sm-75"}>Non trovi il numero del tavolo? <b>Chiedi al personale</b></p>
                    </motion.div>
                }
                <div className={"col-12"}><hr/></div>
                <motion.div initial={{opacity: 0, scale: 0.5}} animate={{opacity: 1, scale: 1}} transition={{duration: 1.3}}  className={"col-12 text-center"}>
                    <div className={"card border border-primary mb-3"}>
                        <div className={"card-body"}>
                            <div className={"row"}>
                                <div className={"col-12 text-start"}>
                                    <h5 className={"fw-bold fs--1 text-primary"}>Non perdere traccia dei tuoi ordini ...</h5>
                                    <label className={"mt-1 fs--2 text-start"}>Inserisci la tua email per registrare il tuo ordine e ricevere in anteprima altre promozioni ed offerte.</label>
                                    <div className="form-group text-start">
                                        <label htmlFor="basketEmail" className="col-sm-3 col-form-label col-form-label-sm text-primary fw-bold d-none">Inserisci tua email</label>
                                        <div className="col-sm-9">
                                            <input type="email" className="form-control form-control-sm" id="basketEmail"
                                                   placeholder="latuaemail@email.com" onChange={(e) => setEmail(e.target.value)}/>
                                        </div>
                                    </div>
                                    {/*}
                                    <hr className={"mt-2 mb-1"}/>
                                    <label className={"mt-0 mb-0 fs--2 text-start fw-normal"}><b className={"me-1"}>Hai un account gloowe?</b>Accedi qui prima di confermare l'ordine.</label>
                                    */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="button" className="border-300 btn btn-primary btn-lg w-100 p-3"
                            onClick={(e) => createOrder(e)}>
                        {onSubmitRequest ? 'Invio ordine ...' : 'Procedi & Ordina'}</button>
                </motion.div>
                <div className={"col-12 mt-3"}>
                    <MessageBanner type={"banner"} is_fixed={false} has_closed={false} code={apiStatus.class} text={apiStatus.textMessage}/>
                </div>
                <div className={"col-12 offset-0 col-sm-6 offset-sm-3"}>
                    <div className={"d-flex align-items-center justify-content-between mt-3"}>
                        <img src={IconBasketComplete} alt={"basket gloowe"} className="img-fluid d-block m-auto mb-0 me-3" width={120}/>
                        <label className={"flex-1 w-100 w-sm-75 mb-0"}>Completa il tuo ordine ed una volta concluso clicca su <b>Procedi & Ordina</b> e <b>mostra</b> il codice ordine o il qr-code al personale.</label>
                    </div>
                </div>

                <ModalContainer>
                    {
                        dataOrderConfirmed.status &&
                        <Modal>
                            <motion.div initial={{opacity: 0, scale: 0.5}} animate={{opacity: 1, scale: 1}} transition={{duration: 0.65}} className={"card mb-0"}>
                                <div className="card-body text-center">
                                    <p className={"fs--1"}>Mostra il <b className={"text-warning"}>codice a 9 cifre</b> al personale ed attendi che venga confermato.</p>
                                    <div className="badge badge-soft-success rounded-3 w-100 py-3 fs-3"
                                    style={{letterSpacing: '12px'}}>{dataOrderConfirmed.code}</div>
                                    {
                                        dataOrderConfirmed.message && <p className={"mt-1 fs--1"}>{dataOrderConfirmed.message}.</p>
                                    }
                                    {
                                        dataOrderConfirmed.need_to_be_confirm
                                            ? <label>Non ti resta che attendere che il personale confermi il tuo ordine per chiudere la finestra.</label>
                                            : <label>Il tuo ordine è stato confermato dal personale.</label>
                                    }
                                    {
                                        dataOrderConfirmed.need_to_be_confirm && dataOrderConfirmed.is_reading &&
                                            <label className={"text-info"}>Ordine è stato letto. Ora attendi che venga confermato dal personale.</label>
                                    }
                                </div>
                                <div className="card-footer p-3 pt-0">
                                    {
                                        dataOrderConfirmed.need_to_be_confirm
                                            ? <SVG_LOADING/>
                                            : <button type="button" className="border-300 btn btn-success w-100" onClick={() => context.ClearBasket()}>Concludi ordine</button>
                                    }
                                    {
                                        dataOrderConfirmed.need_to_be_confirm &&
                                            <div className={"d-flex align-items-center justify-content-between mt-3"}>
                                                <SVG_ICON_WARNING style={"text-warning"}/>
                                                <div className={"flex-1 ms-2"}>
                                                    <i className={"text-warning fs--2"}>Non chiudere la finestra o aggiornare la pagina</i>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </motion.div>
                        </Modal>
                    }
                </ModalContainer>
            </div>
        </div>
    )
}

export default Basket;
