import React, {useContext, useEffect, useState} from "react";
import {AuthAPI} from "../api/AuthAPI";

const AppContext = React.createContext();

const Context = ({children}) => {
    const KEY_STORE_SETTINGS = 'store_settings';
    const TOKEN_MENU_DOMAIN = 'gw-89DTpOWJ';
    const [currentMenuId, setCurrentMenuId] = useState(0);
    const [showBasket, setShowBasket] = useState(false);
    const [dataUpdate, setDataUpdate] = useState(false);
    const [dataStoreSettings, setDataStoreSettings] = useState({
        store_enabled_orders: false,
        store_enabled_order_and_pay: false,
        store_enabled_order_and_confirm_by_client: false,
        store_enabled_delivery: false,
        store_enabled_take_away: false,
        store_enabled_receiver_payments: false,
    });
    const [dataBasket, setDataBasket] = useState({
        total_qta: 0,
        list: []
    });

    useEffect(() => {
        async function fetchData() {
            let responseDataStore = await AuthAPI.myStoreSettings({token_menu: TOKEN_MENU_DOMAIN});
            if (responseDataStore.status === 200) {
                let contextStoreSettings = responseDataStore.data.status ? responseDataStore.data.context : null;
                if (contextStoreSettings === null || undefined) return null;

                let context_store_enabled_orders = '';
                let context_store_enabled_order_and_pay = '';
                let context_store_enabled_order_and_confirm_by_client = '';
                let context_store_enabled_delivery = '';
                let context_store_enabled_take_away = '';
                let context_store_enabled_receiver_payments = '';
                contextStoreSettings.forEach((item) => {
                    let current_meta_value = (/true/).test(item.meta_value);
                    switch (item.meta_key) {
                        case 'store_enabled_orders':
                            context_store_enabled_orders = current_meta_value ?? dataStoreSettings.store_enabled_orders;
                            break;
                        case 'store_enabled_order_and_pay':
                            context_store_enabled_order_and_pay = current_meta_value ?? dataStoreSettings.store_enabled_order_and_pay;
                            break;
                        case 'store_enabled_order_and_confirm_by_client':
                            context_store_enabled_order_and_confirm_by_client = current_meta_value ?? dataStoreSettings.store_enabled_order_and_confirm_by_client;
                            break;
                        case 'store_enabled_delivery':
                            context_store_enabled_delivery = current_meta_value ?? dataStoreSettings.store_enabled_delivery;
                            break;
                        case 'store_enabled_take_away':
                            context_store_enabled_take_away = current_meta_value ?? dataStoreSettings.store_enabled_take_away;
                            break;
                        case 'store_enabled_receiver_payments':
                            context_store_enabled_receiver_payments = current_meta_value ?? dataStoreSettings.store_enabled_receiver_payments;
                            break;
                    }
                });
                setDataStoreSettings({
                    ...dataStoreSettings,
                    store_enabled_orders: context_store_enabled_orders,
                    store_enabled_order_and_pay: context_store_enabled_order_and_pay,
                    store_enabled_order_and_confirm_by_client: context_store_enabled_order_and_confirm_by_client,
                    store_enabled_delivery: context_store_enabled_delivery,
                    store_enabled_take_away: context_store_enabled_take_away,
                    store_enabled_receiver_payments: context_store_enabled_receiver_payments,
                });
                return {
                    store_enabled_orders: context_store_enabled_orders,
                    store_enabled_order_and_pay: context_store_enabled_order_and_pay,
                    store_enabled_order_and_confirm_by_client: context_store_enabled_order_and_confirm_by_client,
                    store_enabled_delivery: context_store_enabled_delivery,
                    store_enabled_take_away: context_store_enabled_take_away,
                    store_enabled_receiver_payments: context_store_enabled_receiver_payments,
                };
            }
            return null;
        }
        if (localStorage.getItem(KEY_STORE_SETTINGS) !== null || undefined) {
            setDataStoreSettings(JSON.parse(localStorage.getItem(KEY_STORE_SETTINGS)))
        } else {
            fetchData().then((response) => {
                if (response !== null) localStorage.setItem(KEY_STORE_SETTINGS, JSON.stringify(response));
            });
        }
    }, [dataUpdate]);

    function setCurrentMenu (menuId = 0) {
        if (showBasket) setShowBasket(false);
        setCurrentMenuId(menuId);
    }

    function GetBasketTotalAmount() {
        let totalAmount = 0;
        dataBasket.list.forEach(function(item, index) {
            totalAmount += parseFloat(item.price);
        });
        return parseFloat(totalAmount).toFixed(2);
    }

    function GetBasketTotalQta(currentList = null) {
        let totalQta = 0;
        let _currentList = (currentList === null) ? dataBasket.list : currentList;
        _currentList.forEach(function(item, index) {
            totalQta += parseInt(item.qta);
        });
        return parseInt(totalQta);
    }

    function AddItem2Basket(item, currentVariation = null, qta = 1) {
        let currentTotalQta = dataBasket.total_qta ?? 0;
        let currentList = [];

        // Control if variations is selected
        if (item.num_variations > 0 && currentVariation === null) return false;

        let currentItem = {
            id: item.id,
            name: item.name,
            layout: item.layout,
            description: item.description,
            list_allergen_id: item.list_allergen_id,
            num_variations: item.num_variations,
            list_variation: item.list_variation,
            variation_selected: currentVariation,
            price: item.price,
            url_menu_consumable_cover: item.url_menu_consumable_cover,
            consumable_id: item.consumable_id
        }

        let isFind = false;
        for (const handlerItem of dataBasket.list) {
            if (handlerItem.item.id === currentItem.id && currentItem.num_variations === 0) {
                currentList.push({
                    qta: parseInt(handlerItem.qta) + parseInt(qta.toString()),
                    price: (parseFloat(handlerItem.price) + parseFloat(currentItem.price)).toFixed(2),
                    item: currentItem
                });
                isFind = true;
            }
            else if (handlerItem.item.id === currentItem.id && currentItem.variation_selected === handlerItem.item.variation_selected) {
                currentList.push({
                    qta: parseInt(handlerItem.qta) + parseInt(qta.toString()),
                    price: (parseFloat(handlerItem.price) + parseFloat(currentItem.variation_selected.price)).toFixed(2),
                    item: currentItem
                });
                isFind = true;
            }
            else currentList.push(handlerItem);
        }
        if (!isFind) {
            let current_price = currentItem.num_variations === 0 ? item.price : currentVariation.price;
            currentList = dataBasket.list;
            currentList.push({
                qta: qta,
                price: parseFloat(current_price).toFixed(2),
                item: currentItem
            });
        }
        setDataBasket({
            ...currentTotalQta
            , total_qta: GetBasketTotalQta(currentList)
            , list: currentList
        });
        return true;
    }

    function RemoveItem2Basket(item, qta = 1){
        let currentTotalQta = dataBasket.total_qta ?? 0;
        let currentList = [];
        for (const handlerItem of dataBasket.list) {
            if (handlerItem.item.id === item.id && item.num_variations === 0) {
                if (qta === 1 && handlerItem.qta > 1) {
                    currentList.push({
                        qta: parseInt(handlerItem.qta) - parseInt(qta.toString()),
                        price: (parseFloat(handlerItem.price) - parseFloat(handlerItem.item.price)).toFixed(2),
                        item: handlerItem.item
                    });
                }
            }
            else if (handlerItem.item.id === item.id && item.variation_selected === handlerItem.item.variation_selected) {
                if (handlerItem.qta > 1) {
                    currentList.push({
                        qta: parseInt(handlerItem.qta) - parseInt(qta.toString()),
                        price: (parseFloat(handlerItem.price) - parseFloat(item.variation_selected.price)).toFixed(2),
                        item: handlerItem.item
                    });
                }
            } else currentList.push(handlerItem);
        }
        setDataBasket({
            ...currentTotalQta
            , total_qta: currentTotalQta - qta
            , list: currentList
        });
    }

    function ClearBasket() {
        setDataBasket({...dataBasket, total_qta: 0, list: []});
        setCurrentMenu();
    }

    async function SendCreateOrderRequest(data) {
        let dataCreateOrder = {
            token_menu: TOKEN_MENU_DOMAIN,
            basket: JSON.stringify(dataBasket),
            email: (data.email ?? null),
            menu_order_id: (data.menu_order_id ?? null),
            table_order: (data.table_order ?? null),
        }

        let responseCreateOrder = {success: false, message: '', context: null};
        let response = await AuthAPI.createOrder(dataCreateOrder);
        if (response.status === 200) {
            responseCreateOrder.success = response.data.status;
            responseCreateOrder.message = response.data.message ?? (response.data.status ? 'Ordine creato correttamente' : 'Impossibile creare un ordine')
            responseCreateOrder.context = response.data.context ?? null;
        } else responseCreateOrder.message = 'Impossibile procedere, non c\'è nessuna connessione ad internet';
        return responseCreateOrder
    }

    return (
        <AppContext.Provider value={{
            TOKEN_MENU_DOMAIN,
            currentMenuId,
            setCurrentMenu,
            dataUpdate,
            setDataUpdate,
            dataStoreSettings,
            dataBasket,
            showBasket,
            setShowBasket,
            AddItem2Basket,
            RemoveItem2Basket,
            GetBasketTotalAmount,
            SendCreateOrderRequest,
            ClearBasket
        }}>
            {children}
        </AppContext.Provider>
    );
}

// This very useful hook allow you to access context data without call useContext hook in every Component
const useGlobalContext = () => {
    return useContext(AppContext);
}
export {useGlobalContext, Context};